import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./component/homePage.js";
import SignIn from "./component/signIn.js";
import SignUp from "./component/signUp";
import AddComponents from "./component/addComponents";
import ListComponents from "./component/listComponents";
import AddGroups from "./component/addGroups";
import ListGroups from "./component/listGroups";
import ListInventory from "./component/listInventory.js";
import UpdateInventory from "./component/updateInventory";
import AddOutlet from "./component/addOutlet.js";
import ListOutlets from "./component/listOutlets.js";
import AddST from "./component/addStockTransfer.js";
import ListST from "./component/listStockTransfer.js";
import AddPI from "./component/addPurchaseInvoice.js";
import ListPI from "./component/listPurchaseInvoice.js";
import AddVendor from "./component/addVendor.js";
import ListVendor from "./component/listVendor.js";
import ListUsers from "./component/listUsers.js";
import ListQuotations from "./component/listQuotations.js";
import ListOrder from "./component/listOrder.js";
import ReadQuotation from "./component/readQuotation.js";
import ManageFormula from "./component/manageFormula.js";
import ManageShopDetails from "./component/manageShopDetails.js";
import AddLead from "./component/addLead.js";
import ListLeads from "./component/listLeads.js";
import Analytics from "./component/analytics.js";
import InventoryHistory from "./component/inventoryHistory.js";
import { theme } from "antd";
import SidebarLayout from "./sidebarLayout.js";
import AddEmployee from "./component/addEmployee.js";
import ListEmployee from "./component/listEmployee.js";
import SqftCompQuot from "./component/sqftCompQuot.js";
import ListProduction from "./component/listProduction.js";
import ListPO from "./component/listPurchaseOrder.js";
import AddPO from "./component/addPurchaseOrder.js";
import CalProfileMultiple from "./component/calProfileMultiple.js";
import PurchasePayments from "./component/purchasePayments.js";
import ListInvoice from "./component/listInvoice.js";
import AddInvoice from "./component/addInvoice.js";
import ListInstallation from "./component/listInstallation.js";
import AddTicket from "./component/addTicket.js";
import ListTickets from "./component/listTickets.js";
import AddPR from "./component/addPurchaseRequest.js";
import ListPR from "./component/listPurchaseRequest.js";
import AddCoating from "./component/addSendCoating.js";
import SendCoating from "./component/listSendCoating.js";
import PaymentHistory from "./component/paymentHistory.js";
import ListAtndnc from "./component/listAtndnc.js";
import AddAtndnc from "./component/addAtndnc.js";
import ViewAttend from "./component/viewAttend.js";
import ListPayroll from "./component/listPayroll.js";
import AddPayroll from "./component/addPayroll.js";
import AddExpense from "./component/addExpense.js";
import ListExpense from "./component/listExpense.js";
import DebitReceipt from "./component/debitReceipt.js";
import SalesPayments from "./component/salesPayments.js";
import CreditReceipt from "./component/creditReceipt.js";
import ReportExpense from "./component/reportExpense.js";
import ChangePassword from "./component/changePassword.js";
import ReportSales from "./component/reportSales.js";
import ReportPurchase from "./component/reportPurchase.js";
import ReportLeads from "./component/reportLeads.js";
import ReportProd from "./component/reportProd.js";
import ReportInstall from "./component/reportInstall.js";
import OutletSales from "./component/outletSales.js";
import ReportProdGroup from "./component/reportProdGroup.js";
import ReportFollowUp from "./component/reportFollowUp.js";
import ReportSource from "./component/reportSource.js";
import UploadAtndnc from "./component/uploadAtndnc.js";
import ReportBank from "./component/reportBank";
import AddLoan from "./component/addLoan.js";
import ListLoan from "./component/listLoan.js";
import ReportType from "./component/reportType";
import ReportCoating from "./component/reportCoating.js";
import ReportInvoice from "./component/reportInvoice.js";
import InvoiceStmt from "./component/invoiceStmt.js";
import ReportOutletInvc from "./component/reportOutletInvoice";
import ReportProfitLoss from "./component/reportProfitLoss.js";
import ForgotPassword from "./component/forgotPassword.js";

function App() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <div style={{ height: "12" }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" index element={<SignIn />} />
            <Route element={<SidebarLayout />}>
              <Route path="SignUp" element={<SignUp />} />
              <Route path="HomePage" element={<HomePage />} />
              <Route path="AddComponents/:test" element={<AddComponents />} />
              <Route path="ListComponents" element={<ListComponents />} />
              <Route path="AddGroups/:test" element={<AddGroups />} />
              <Route path="ListGroups" element={<ListGroups />} />
              <Route path="ListInventory/:test" element={<ListInventory />} />
              <Route
                path="UpdateInventory/:test"
                element={<UpdateInventory />}
              />
              <Route path="AddOutlet/:test" element={<AddOutlet />} />
              <Route path="ListOutlets" element={<ListOutlets />} />
              <Route path="AddST/:test" element={<AddST />} />
              <Route path="ListST" element={<ListST />} />
              <Route path="AddPI/:test" element={<AddPI />} />
              <Route path="ListPI" element={<ListPI />} />
              <Route path="AddVendor/:test/:from" element={<AddVendor />} />
              <Route path="ListVendor/:test" element={<ListVendor />} />
              <Route path="ListUsers/:test/:from" element={<ListUsers />} />
              <Route path="ListQuotations/:test" element={<ListQuotations />} />
              <Route path="ListOrder" element={<ListOrder />} />
              <Route
                path="ReadQuotation/:test/:from"
                element={<ReadQuotation />}
              />
              <Route path="ManageFormula" element={<ManageFormula />} />
              <Route path="ManageShopDetails" element={<ManageShopDetails />} />
              <Route path="AddLead/:test" element={<AddLead />} />
              <Route path="ListLeads" element={<ListLeads />} />
              <Route path="Analytics" element={<Analytics />} />
              <Route
                path="InventoryHistory/:test/:from"
                element={<InventoryHistory />}
              />
              <Route path="AddEmployee/:test" element={<AddEmployee />} />
              <Route path="ListEmployee" element={<ListEmployee />} />
              <Route
                path="SqftCompQuot/:test/:from"
                element={<SqftCompQuot />}
              />
              <Route path="ListProduction" element={<ListProduction />} />
              <Route path="AddPO/:test" element={<AddPO />} />
              <Route path="ListPO" element={<ListPO />} />
              <Route
                path="CalProfileMultiple/:test"
                element={<CalProfileMultiple />}
              />
              <Route path="PurchasePayments" element={<PurchasePayments />} />
              <Route path="ListInvoice" element={<ListInvoice />} />
              <Route path="AddInvoice/:test" element={<AddInvoice />} />
              <Route path="ListInstallation" element={<ListInstallation />} />
              <Route path="AddTicket/:test" element={<AddTicket />} />
              <Route path="ListTickets" element={<ListTickets />} />
              <Route path="AddPR/:test" element={<AddPR />} />
              <Route path="ListPR" element={<ListPR />} />
              <Route path="AddCoating/:test" element={<AddCoating />} />
              <Route path="SendCoating" element={<SendCoating />} />
              <Route
                path="PaymentHistory/:test/:from"
                element={<PaymentHistory />}
              />
              <Route path="AddAtndnc/:test" element={<AddAtndnc />} />
              <Route path="ListAtndnc" element={<ListAtndnc />} />
              <Route path="ViewAttend/:test" element={<ViewAttend />} />
              <Route path="AddPayroll/:test" element={<AddPayroll />} />
              <Route path="ListPayroll" element={<ListPayroll />} />
              <Route path="AddExpense/:test" element={<AddExpense />} />
              <Route path="ListExpense" element={<ListExpense />} />
              <Route
                path="DebitReceipt/:test/:from"
                element={<DebitReceipt />}
              />
              <Route path="SalesPayments" element={<SalesPayments />} />
              <Route
                path="CreditReceipt/:test/:from"
                element={<CreditReceipt />}
              />
              <Route path="ReportExpense" element={<ReportExpense />} />
              <Route path="ReportSales" element={<ReportSales />} />
              <Route path="ReportPurchase" element={<ReportPurchase />} />
              <Route path="ReportLeads" element={<ReportLeads />} />
              <Route path="ReportProd" element={<ReportProd />} />
              <Route path="ReportInstall" element={<ReportInstall />} />
              <Route path="ChangePassword" element={<ChangePassword />} />
              <Route path="OutletSales" element={<OutletSales />} />
              <Route path="ReportProdGroup" element={<ReportProdGroup />} />
              <Route path="ReportFollowUp" element={<ReportFollowUp />} />
              <Route path="ReportSource" element={<ReportSource />} />
              <Route path="UploadAtndnc" element={<UploadAtndnc />} />
              <Route path="ReportBank" element={<ReportBank />} />
              <Route path="AddLoan/:test" element={<AddLoan />} />
              <Route path="ListLoan" element={<ListLoan />} />
              <Route path="ReportType" element={<ReportType />} />
              <Route path="ReportCoating" element={<ReportCoating />} />
              <Route path="ReportInvoice" element={<ReportInvoice />} />
              <Route path="InvoiceStmt" element={<InvoiceStmt />} />
              <Route path="ReportOutletInvc" element={<ReportOutletInvc />} />
              <Route path="ReportProfitLoss" element={<ReportProfitLoss />} />
              <Route path="ForgotPassword" element={<ForgotPassword />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
