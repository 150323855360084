import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, DatePicker, Select } from "antd";
import { getItemsByDatePagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import moment from "moment";

function ReportExpense() {
  const navigate = useNavigate();
  const [shopId, setShopId] = useState("");
  const [reportList, setReportList] = useState([]);
  const [bankName, setBankName] = useState("");
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          getAllItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (shopId) => {
    let temp = [];
    let Expense = await getItemsByDatePagination(
      "Expense",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    let SalesPayment = await getItemsByDatePagination(
      "SalesPayment",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    let PurchasePayment = await getItemsByDatePagination(
      "PurchasePayment",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    temp = Expense.concat(SalesPayment).concat(PurchasePayment);
    temp = temp.sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);
      return dateA - dateB;
    });
    setReportList(temp);
    setSpinnerState(false);
  };

  useEffect(() => {
    if (shopId != "") {
      setReportList([]);
      setSpinnerState(true);
      getAllItemsByType(shopId);
    }
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          // width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{ marginBottom: 20, display: "flex", alignContent: "center" }}
        >
          <p style={{ marginRight: 20 }}>Bank Name</p>
          <Select
            style={{ width: 250, marginTop: 5 }}
            value={bankName}
            onChange={(value) => {
              setBankName(value);
            }}
          >
            <Option value="">Select a bank</Option>
            <Option value="HDFC Bank">HDFC Bank</Option>;
            <Option value="IndusInd Bank">IndusInd Bank</Option>;
            <Option value="IndusInd Rejula">IndusInd Rejula</Option>;
            <Option value="Axis Bank">Axis Bank</Option>;
          </Select>
          <p style={{ marginRight: 20, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
        </div>
        <h2>
          Bank transaction between {dateRange[0].format("DD MMM YY")}
          {" - "}
          {dateRange[1].format("DD MMM YY")}
        </h2>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ width: 150 }}>Date</p>
                  <p style={{ width: 150 }}>Bank Name</p>
                  <p style={{ width: 150 }}>Type</p>
                  <p style={{ width: 150 }}>From</p>
                  <p
                    style={{
                      width: 150,
                      color: "Green",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    Credit
                  </p>
                  <p
                    style={{
                      width: 150,
                      color: "Red",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    Debit
                  </p>
                </div>
                {reportList.map((item) => {
                  return (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <p style={{ width: 150 }}>
                          {moment(item.addedDate).format("DD MMM YY")}
                        </p>
                        <p style={{ width: 150 }}>{item.bankName}</p>
                        <p style={{ width: 150 }}>{item.type}</p>
                        <p style={{ width: 150 }}>
                          {item.location
                            ? item.location
                            : item.vendorName
                            ? item.vendorName
                            : "Self"}
                        </p>
                        <p
                          style={{
                            width: 150,
                            color: "Green",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          {item.type == "SalesPayment"
                            ? item.installmentAmount
                            : ""}
                        </p>
                        <p
                          style={{
                            width: 150,
                            color: "Red",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          {item.type != "SalesPayment"
                            ? item.installmentAmount
                              ? item.installmentAmount
                              : item.totalAmount
                            : ""}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
