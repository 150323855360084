import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, DatePicker } from "antd";
import { getItemsdatePagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import * as queries from "../graphql/queries";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";

function ReportExpense() {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [outletShopId, setOutletShopId] = useState("");
  const [expList, setExpList] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let temp = {};
    let categoryList = [];
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      categoryList = allTodos.data.getItemById.expCatList;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
    setAllItems(categoryList);

    let STDate = dateRange[0];
    let EDDate = dateRange[1];
    do {
      let key = EDDate.format("MM") + EDDate.format("YYYY");
      temp[key] = {};
      temp[key].month = EDDate.format("MMM");
      temp[key].mnth = EDDate.format("MM");
      temp[key].year = EDDate.format("YYYY");
      temp[key].total = 0;
      if (EDDate.isBefore(STDate, "month") || EDDate.isSame(STDate, "month")) {
        break;
      }
      EDDate = EDDate.subtract(1, "months");
    } while (true);
    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");
      let allItems = await getItemsdatePagination(
        "Expense",
        outletShopId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
      let categoryListTemp = {};
      categoryList.map((item) => {
        categoryListTemp[item] = {};
        categoryListTemp[item].total = 0;
      });
      let catList = categoryListTemp;
      if (allItems.length > 0)
        allItems.map((item) => {
          value.total = value.total + parseFloat(item.totalAmount);
          let category = item.itemDescription;
          if (catList[category] != undefined) {
            catList[category].total =
              catList[category].total + parseFloat(item.totalAmount);
          }
        });
      value.categoryList = catList;
    }
    setSpinnerState(false);
    // Convert to an array and sort it
    let sortedArray = Object.values(temp).sort((a, b) => {
      // Compare years first
      if (a.year !== b.year) {
        return b.year - a.year;
      }
      // If years are the same, compare months
      return b.mnth - a.mnth;
    });
    setExpList(sortedArray);
  };

  useEffect(() => {
    if (outletShopId != "") {
      setExpList([]);
      setSpinnerState(true);
      getAllItemsByType(outletShopId);
    }
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div style={{ marginBottom: 20, display: "flex" }}>
          <p style={{ marginRight: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
            picker="month"
          />
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    alignItems: "center",
                    border: "1px solid black",
                    fontWeight: "bold",
                    paddingLeft: 10,
                  }}
                >
                  <p style={{ width: 120 }}>Month</p>
                  <p style={{ width: 120 }}>Year</p>
                  <p>
                    {allItems.map((item) => {
                      return (
                        <div>
                          <p style={{ width: 120 }}>{item}</p>
                        </div>
                      );
                    })}
                  </p>
                  <p style={{ width: 120 }}>Total</p>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {expList.map((value) => {
                    return (
                      <div
                        style={{
                          alignItems: "center",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderTop: "1px solid black",
                          textAlign: "right",
                          paddingRight: 10,
                        }}
                      >
                        <p style={{ width: 120, fontWeight: "bold" }}>
                          {value.month}
                        </p>
                        <p style={{ width: 120 }}>{value.year}</p>
                        <p>
                          {value.categoryList &&
                            Object.entries(value.categoryList).map(
                              ([key, value]) => {
                                return (
                                  <div>
                                    <p style={{ width: 120 }}>{value.total}</p>
                                  </div>
                                );
                              }
                            )}
                        </p>
                        <p style={{ width: 120, fontWeight: "bold" }}>
                          {value.total}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
